import React from "react"
import { graphql } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "../components/layout"
import { Flex, Title, Label, Box, Button } from "../components/system"
import Video from "../components/Video"
import ModuleOne from "../components/Modules/ModuleOne"
import Seo from "../components/seo"

const Community = ({ data }) => {
  const { label, title, video, blocks } = data.sanityCommunityPage
  const { loginWithRedirect } = useAuth0()

  return (
    <Layout dark>
      <Seo title="Join our community" />
      <Flex flexDirection="column" pt={["40%", "12%"]}>
        <Label fontSize={1} color="faded">
          {label}
        </Label>
        <Title fontSize={[6, 7]} color="secondary">
          {title}
        </Title>
      </Flex>
      <Box mx={[0, 0, 6]} mt={4}>
        <Video url={video.asset.url} />
      </Box>

      <Box mx={[3, 4, 6]}>
        {blocks &&
          blocks.map((block, key) => (
            <ModuleOne data={block} key={key} number={key} />
          ))}
      </Box>
      <Flex flexDirection="column" p={[5, 6]} bg="secondary" color="white">
        <Title mb={2} fontSize={[5, 6]}>
          Create Your Account
        </Title>
        <Label mb={4} down textAlign="center">
          Start your fashion journey today.
        </Label>
        <Flex>
          <Button onClick={() => loginWithRedirect()} bg="black" color="white">
            Get Started
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default Community

export const pageQuery = graphql`
  query community {
    sanityCommunityPage {
      label
      title
      video {
        asset {
          url
        }
      }
      blocks {
        title
        text
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
