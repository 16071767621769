import React from "react"
import { Flex, Box, Title, Text } from "../system"
import { GatsbyImage } from "gatsby-plugin-image"

const ModuleOne = ({ data, number }) => {
  const even = number % 2 === 0

  return (
    <Flex
      flexWrap="wrap"
      flexDirection={["column-reverse", even ? "row" : "row-reverse"]}
      my={6}
    >
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        width={["100%", "50%"]}
      >
        <Title fontSize={[5, 5, 6]} color="secondary" textAlign="left">
          {data.title}
        </Title>
        <Text textAlign="left" pr={[0, 3, 6]}>
          {data.text}
        </Text>
      </Flex>
      <Box
        width={["100%", "50%"]}
        mb={[4, 0]}
        pl={[0, even ? 6 : 0]}
        pr={[0, even ? 0 : 6]}
      >
        <GatsbyImage
          image={data.image.asset.gatsbyImageData}
          style={{ borderRadius: 0 }}
        />
      </Box>
    </Flex>
  )
}

export default ModuleOne
